body {
    background: #fff;
    color: #000;
}
h1 {
    font-size: 64px;
}
h2 {
    font-size: 48px;
    line-height: 45px;
}
a {
    color: #000;
    cursor: pointer;
}

section {
    padding: 5% 0;
}
.button.pink {
    background-color: #ffb6a3;
}
.button.senf {
    background-color: #f0bc68;
}
#continueButton{
    color: red!important;
}


.c-nav-container {
    background-color: #5f9595;
    height: 70px;
    position: fixed;
    z-index: 2;
    width: 100%;

    .menu {
        
        a{
            color: #fff;
            font-family: 'Josefin Sans', sans-serif;
            font-weight: 700;
            font-size: 18px;
        }

    }
    &--social {
        margin-top: 16px;
        a{
            padding-right: 5px;
            padding-left: 5px;
        }
        
    }
    &--menu {
        margin-top: 16px;
    }
    &--logo{
        margin-top: 5px;

        a{
            padding-top: 5px;
            @include breakpoint(medium) {
                padding-top: 10px;
            }
        }

        .logo{
            width: 150px;
            @include breakpoint(medium) {
                width: 200px;
            }
        }
    }
}

.text-small{
    font-size: 12px;
}
hr {
    &.light {
        border-bottom: 1px solid $white;
    }
}
.cc-revoke {
    display: none!important;
}

.o-section {
    position: relative;
    color: #000;

    &--light {
        background: #f5d1c3;
    }
    &--white {
        background: #fff;
        color: #000;

        .button.hollow{
            border-color: #000;
            color: #000;
        }
        p {
            color: #000;
        }
        h2{
            color: #000;
        }
    }

    &--gallery {
        padding-top: 0;
    }

    &--footer {
        padding: 5% 0 0 0;
        color: #000;

        &--sub{
            .menu {
                a {
                    color: #000;
                    padding-top: 10px;
                } 
            }
        }

    }

    &--opening-hours {
        dl {
            display: flex;
            flex-flow: row wrap;
            color: #595959;
            margin-bottom: 0px;

            &.today {
                font-weight: bold;
                color: #000;
            }
        }

        dt { 
            flex-basis: 40%;
        }

        dd {
            text-align: right;
            flex-grow: 1;
        }
    }
}

.c-hero-section {
    &__header {
        position: relative;
    }

    &__bg-1 {
        height: 100vh;
        width: 100%;
        background: url('../img/bg.jpg') 50% no-repeat;
        background-size: cover;
        z-index: 2;

        @include breakpoint(small only) {
            height: 80vh;
        }
    }

    &__button {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 20px;

        .button.large{
            @include breakpoint(small only) {
                font-size: 14px;
            }
        }
    }
}
.c-food {
    background-color: #c4d7d1;
    &--category{
        color: #000;
        font-family: 'Amatic SC', cursive;
        font-weight: 700;
        &_spacer{
            margin-top: 30px;
            @include breakpoint(small only) {
                margin-top: 0px;
            }
        }
    }
    &--name{
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        margin-bottom: 0px;
    }
    &--parts{
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 300;
    }
    &--price{
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;

    }
}
